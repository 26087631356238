import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import { pageFadeInOut } from '@/modules/animationVariants'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import CounterContainer from './about/CounterContainer'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const AboutPage: React.FC = () => {
  const { t } = useTranslation()

  const content = {
    pretitle: t('home.about_super', 'fill copy key: home.about_super'),
    title: t('home.about_title', 'fill copy key: home.about_title'),
    text: t('home.about_body', 'fill copy key: home.about_body'),
    links: [
      {
        link: t('home.about_cta', 'fill copy key: home.about_cta'),
        url: '/home/reliable',
        visibleOnDesktop: true,
        visibleOnXl: true,
      },
    ],
  }

  const counterContent = {
    header1: t(
      'home.about_stats1_header',
      'fill copy key: home.about_stats1_header',
    ),
    prefix1: '€',
    number1: Number(
      t('home.about_stats1_number', 'fill copy key: home.about_stats1_number'),
    ),
    postfix1: 'B<sup>+</sup>',
    header2: t(
      'home.about_stats2_header',
      'fill copy key: home.about_stats2_header',
    ),
    prefix2: '',
    number2: Number(
      t('home.about_stats2_number', 'fill copy key: home.about_stats2_number'),
    ),
    postfix2: t(
      'home.about_stats2_postfix',
      'fill copy key: home.about_stats2_postfix',
    ),
  }

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[
                <CounterContainer {...counterContent} />,
                <Text isMobile {...content} />,
              ]}
            >
              <Box row>
                <Box col={0.5}>
                  <Box col={0.8}>
                    <Text {...content} />
                  </Box>
                </Box>
                <Box
                  col={0.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box col={0.8}>
                    <CounterContainer {...counterContent} />
                  </Box>
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default AboutPage
